import { environment } from '@env';
import { NgModule } from '@angular/core';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { APP_CONFIG, AppConfig } from '@core/keycloak/keycloak-init.service';
import { Auth0Provider } from '@core/providers/auth0-auth.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HttpAuthInterceptor } from '@core/interceptors/http-auth/http-auth.interceptor';

@NgModule({
  declarations: [],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_SPA_CLIENT_ID,
      httpInterceptor: {
        allowedList: [
          `${environment.BACKEND_DOMAIN}/*`,
          `${environment.BACKOFFCIE_URL}/*`,
          `${environment.AUDIT_SERVICE_URL}/*`,
          `${environment.DT_ENGINE_URL}/*`,
          `${environment.SOLUTION_CENTER_URL}/*`,
          `${environment.AUTH_SERVICE_URL}/api/*`,
          `${environment.AUTH0_DOMAIN}/*`,
          `${environment.RUNTIME_SERVICE_URL}/*`,
          `${environment.FLAGS_ROUTER_SERVICE_URL}/*`,
          `${environment.NOTIFICATION_SERVICE_URL}/*`,
          `${environment.PLANNER_SERVICE_URL}/*`,
        ],
      },
      authorizationParams: {
        scope: 'openid profile email',
        redirect_uri: window.location.origin,
      },
    }),
  ],
  exports: [AppRoutingModule],
  providers: [
    {
      provide: HubAuthService,
      multi: false,
      useFactory: (appConfig: AppConfig, auth0Service: AuthService) =>
        new HubAuthService(null, new Auth0Provider(auth0Service), null),
      deps: [APP_CONFIG, AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  ],
})
export class AppMainModule {}
