import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'hub',
  },
  {
    path: 'hub',
    loadChildren: () => import('./features/hub/hub.module').then((m) => m.HubModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mpt',
    loadChildren: () => import('./features/mpt/mpt.module').then((m) => m.MptModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./features/subscription/subscription.module').then((m) => m.SubscriptionModule),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./shared/features/hub-page-not-found/page-not-found.component').then(
        (c) => c.PageNotFoundComponent,
      ),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
