export {};

declare global {
  interface String {
    toKebabCase(): string;
    toPlural(): string;
    capitalizeFirstLetter(): string;
  }
}

String.prototype.toKebabCase = function (): string {
  return this.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-');
};

String.prototype.toPlural = function (): string {
  if (this.endsWith('y')) {
    return this.slice(0, -1) + 'ies';
  } else if (['s', 'x', 'z', 'ch', 'sh'].some((character) => this.endsWith(character))) {
    return this + 'es';
  } else {
    return this + 's';
  }
};

String.prototype.capitalizeFirstLetter = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
