import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { environment } from '@env';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsModule } from '@ngxs/store';
import { AppComponent } from './app.component';
import { AppEnterpriseModule } from './app-enterprise.module';
import { AppMainModule } from './app-main.module';
import { AppLiteHubModule } from './app-lite-hub.module';
import { TagInputModule } from 'ngx-chips';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IS_HUB_ENTERPRISE_OR_HUB_LITE, IS_HUB_LITE } from '@core/hubconfig';
import { AppState } from '@core/store/application/application.state';
import { UserState } from '@core/store/user/user.state';
import { NavbarState } from '@core/store/navbar/navbar.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    NgxsModule.forRoot([AppState, UserState, NavbarState], {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    NoopAnimationsModule,
    TagInputModule,
    environment.plugins,
    NgSelectModule,
    /* eslint-disable no-nested-ternary */
    environment.enterprise ? AppEnterpriseModule : IS_HUB_LITE ? AppLiteHubModule : AppMainModule, // TODO: find better way
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !IS_HUB_ENTERPRISE_OR_HUB_LITE,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
