import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { ConfigService, HubEnterpriseConfig } from '@core/keycloak/config.service';
import { APP_CONFIG, AppConfig, KeycloakInitService } from '@core/keycloak/keycloak-init.service';
import { environment } from '@env';
import { HubAuthService } from '@core/services/hub-auth/hub-auth.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { KeycloakProvider } from '@core/providers/keycloack-auth.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnterpriseHttpInterceptor } from '@core/providers/interceptors/enterprise-http-interceptor';
import { AppRoutingEnterpriseModule } from './app-routing-enterprise.module';

@NgModule({
  declarations: [],
  imports: [KeycloakAngularModule, AppRoutingEnterpriseModule],
  exports: [AppRoutingEnterpriseModule],
  providers: [
    KeycloakService,
    provideAppInitializer(() => {
      const initializerFn = ((
        configService: ConfigService,
        keycloakInitService: KeycloakInitService,
      ) => {
        if (environment.enterprise) {
          return () =>
            configService
              .loadConfig()
              .then((res) => keycloakInitService.init(res as HubEnterpriseConfig));
        }
        return () => Promise.resolve(true);
      })(inject(ConfigService), inject(KeycloakInitService));
      return initializerFn();
    }),
    {
      provide: HubAuthService,
      multi: false,
      useFactory: (appConfig: AppConfig, keycloakService: KeycloakService) =>
        new HubAuthService(new KeycloakProvider(keycloakService), null, null),
      deps: [APP_CONFIG, KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnterpriseHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppEnterpriseModule {}
